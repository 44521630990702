button {
  &.preview-agent-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3d52f6;
    box-shadow: 0 0 1px rgb(40 41 61 / 4%), 0 2px 4px rgb(96 97 112 / 16%);
    border-radius: 40px;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    width: 131px;
    height: 48px;
    border: none !important;
  }
}
