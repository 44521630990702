@use "./global.scss";
@use "./App-responsive.scss";

* {
  font-family: "DM Sans", sans-serif;
}

.Toastify__toast-container {
  // Set to show alerts on top of everything
  z-index: 9999999999;
}
// Onboarding stuff logic lives here.
#onboarding {
  .main-container {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
    border-radius: 30px;
    padding: 48px;
    max-width: 1086px;
    width: 100%;
    align-items: center;
    padding-top: 0px;
    min-height: 608px;
    background-size: 386px;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: -85px;
    > div {
      &:not(.gc-progress-bar, .gc-avatar-container) {
        width: 100%;
        height: 100% !important;
      }
    }

    .gc-yelp-image {
      width: 200px;
      border-radius: 10px 0px 0px 10px;
      background-size: cover;
      background-position: center;
    }
    .gc-yelp-content {
      padding: 16px 16px 16px 24px;
      position: static;
      width: 296px;
      height: 100%;
      .text-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        padding-top: 12px;
        align-items: baseline;
        line-height: 21px;
      }
      .icon {
        width: 20px;
        height: 20px;
        color: #686868;
        margin-right: 12px;
      }
    }
    .gc-yelp-footer-form {
      height: 98px !important;
      margin-bottom: 0px !important;
      .footer {
        margin: auto !important;
      }
    }

    .gc-headline {
      padding-top: 70px;
    }

    .gc-sub-header {
      font-family: Inter;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
    .gc-greeting-picker {
      width: 100%;
      .agent-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 32px 0px;
      }
    }

    .progress {
      height: 5px !important;
      background-color: #d2deef !important;
    }
    .progress-bar {
      background-color: #3d52f6;
    }
    .gc-avatar {
      transform: rotate(227deg);
      border-radius: 0px 50% 50%;
      filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.04)) drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.16));
      width: 50px;
      background: #ffffff;
      width: 105px;
      height: 105px;
      position: absolute;
      margin-top: -108px;
      display: none;
      .img {
        width: 85px;
        height: 85px;
        border-radius: 50% !important;
        transform: rotate(-227deg);
        margin-top: 7px;
        margin-left: 7px;
        background-size: contain;
      }
    }
    .gc-avatar-container .agent-container .avatar {
      transform: rotate(-227deg);
      margin-top: 4px;
      margin-left: 4px;
    }
    .gc-avatar-container .gc-avatar {
      align-items: center;
    }
    .gc-avatar-container {
      place-content: center;
    }
  }
}
