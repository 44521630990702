@use "./colors" as *;

.fadein-600ms {
  animation: fadeIn 0.6s;
  -webkit-animation: fadeIn 0.6s;
  -moz-animation: fadeIn 0.6s;
  -o-animation: fadeIn 0.6s;
  -ms-animation: fadeIn 0.6s;
}

.fadein-300ms {
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulseAnimation {
  0% {
    color: $white;
  }
  50% {
    color: $navigation-inactive;
  }
  100% {
    color: $white;
  }
}

@keyframes agentPulseAnimation {
  0% {
    color: $navigation-disabled;
  }
  100% {
    color: $base-dark-blue;
  }
}

.pulse-animation {
  animation: pulseAnimation 2s infinite;
}

.agent-helper-pulse-animation {
  animation: agentPulseAnimation 2s infinite;
}
