$primary-font-family: "DM Sans", sans-serif;
$secondary-font-family: Inter;

// Weights
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

// Mixin for DM fonts
@mixin font-weights($size) {
  &-bold {
    font-size: $size;
    font-weight: $font-weight-bold;
  }

  &-light {
    font-size: $size;
    font-weight: $font-weight-light;
  }
}

@mixin heading-styles($size) {
  &-regular {
    font-size: $size;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
  }

  &-bold {
    font-size: $size;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  &-light {
    font-size: $size;
    font-weight: $font-weight-regular;
    margin-bottom: 0;
  }
}

// Buttons
// @use <button className="large-light"> Hello </button>
button {
  &.large {
    @include font-weights(1.2rem);
  }

  &.medium {
    @include font-weights(1rem);
  }

  &.small {
    @include font-weights(0.833rem);
  }
}

.h1 {
  @include heading-styles(2.488rem);
}

.h2 {
  @include heading-styles(2.074rem);
}

.h3 {
  @include heading-styles(1.728rem);
}

.h4 {
  @include heading-styles(1.44rem);
}

.h5 {
  @include heading-styles(1.2rem);
}

.lead {
  font-family: $secondary-font-family;
  font-size: 1rem;

  &-regular {
    font-weight: $font-weight-regular;
    @extend .lead;
  }

  &-bold {
    font-weight: $font-weight-medium;
    @extend .lead;
  }

  &-light {
    font-weight: $font-weight-light;
    @extend .lead;
  }
}

.body-1 {
  font-family: $secondary-font-family;
  font-size: 0.833rem;

  &-regular {
    font-weight: $font-weight-regular;
    @extend .body-1;
  }

  &-bold {
    font-weight: $font-weight-medium;
    @extend .body-1;
  }

  &-light {
    font-weight: $font-weight-light;
    @extend .body-1;
  }
}

.body-2 {
  font-family: $secondary-font-family;
  font-size: 0.694rem;

  &-regular {
    font-weight: $font-weight-regular;
    @extend .body-2;
  }

  &-bold {
    font-weight: $font-weight-medium;
    @extend .body-2;
  }

  &-light {
    font-weight: $font-weight-light;
    @extend .body-2;
  }
}

.subtext {
  font-family: $secondary-font-family;
  font-size: 0.579rem;

  &-regular {
    font-weight: $font-weight-regular;
    @extend .subtext;
  }

  &-bold {
    font-weight: $font-weight-medium;
    @extend .subtext;
  }

  &-light {
    font-weight: $font-weight-light;
    @extend .subtext;
  }
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.text-nowrap {
  text-wrap: nowrap;
}
