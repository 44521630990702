// $breakpoint defines which device size we're targeting.
// Either mobile, tablet, or desktop, large, or small.
@mixin devices($breakpoint) {
  @if $breakpoint == mobile {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint == medium {
    @media only screen and (max-width: 990px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 1199px) {
      @content;
    }
  }
  @if $breakpoint == xlarge {
    @media screen and (max-width: 1400px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == large {
    @media screen and (min-width: 901px) {
      @content;
    }
  }

  @if $breakpoint == small {
    @media screen and (max-width: 901px) {
      @content;
    }
  }

  @if $breakpoint == xMobile {
    @media screen and (min-width: 991px) {
      @content;
    }
  }

  // Most of the traffic from small devices : 390px
  @if $breakpoint == xsmall {
    @media screen and (max-width: 390px) {
      @content;
    }
  }
}

@mixin hidden($breakpoint) {
  @include devices($breakpoint) {
    display: none !important;
  }
}

.conversight .hidden {
  &-mobile {
    @include hidden(mobile);
  }
  &-tablet {
    @include hidden(tablet);
  }
  &-desktop {
    @include hidden(desktop);
  }
  &-large {
    @include hidden(large);
  }
  &-small {
    @include hidden(small);
  }
  &-xsmall {
    @include hidden(xsmall);
  }
}
