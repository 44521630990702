@use "../converSight/variables" as *;
@use "./buttons.scss";

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
::placeholder {
  color: #959393 !important;
  font-weight: 100;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes placeHoldShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.gc-progress-bar {
  background: #ecf1f8;
  width: 100%;
  height: 28px;
  width: calc(100% + 95px);
  border-radius: 30px 30px 0px 0px;
  margin-bottom: 20px;
  .progress-bar-filled {
    background-color: #05164c;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px 30px 30px 0px;
    color: #ffffff;
  }
  .circle {
    width: 8px;
    height: 8px;
    background: #ecf1f8;
    margin: 0px 24px;
    border-radius: 50%;
  }
  .selected {
    background: #05164c !important;
  }
}
.gc-btn-white {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 16px !important;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #08111a !important;
}
.gc-cta-button {
  border-radius: 6px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 32px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 32px !important;
  &:disabled {
    background: #959393 !important;
    opacity: unset !important;
  }
}

.gc-callout {
  filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.04)) drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.16));
  padding: 16px;
  width: 256px;
  border-radius: 10px;
  background: #ffffff;
  margin-left: 170px;
  bottom: 30%;
  position: absolute;
  .speak-arrow {
    width: 17px;
    height: 19px;
    background: white;
    position: absolute;
    left: -6px;
    top: 36%;
    transform: rotate(49deg);
  }
}
.gc-callout-notext {
  padding: 16px;
  width: 256px;
  margin-left: 170px;
  bottom: 30%;
  position: absolute;
}

.gc-b3 {
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-weight: 300 !important;
  .btn {
    font-size: 12px !important;
    font-family: "Inter" !important;
    font-weight: 300 !important;
  }
}
.gc-b2 {
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 300 !important;
  .btn {
    &:not(.font-weight-500) {
      font-size: 14px !important;
      font-family: "Inter" !important;
      font-weight: 300 !important;
    }
  }
}
.gc-b1 {
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
}
.gc-h1 {
  font-size: 30px;
  line-height: 39px;
  font-weight: 500;
}
.gc-h2 {
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}
.gc-h3 {
  font-size: 18px !important;
  font-weight: 500 !important;
  .btn {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}
.gc-h4 {
  font-size: 14px !important;
  font-weight: 500 !important;
  .btn {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.gc-h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.show-on-small-screen {
  display: none;
}
.gc-cta-500 {
  background-color: #f82ba4 !important;
}
.gc-btn-primary-500 {
  background-color: #0397e7 !important;
  background-color: #0397e7 !important;
}

.gc-dark-blue {
  background-color: #05164c !important;
}
.gc-no-bg {
  background: none;
}
.gc-no-border {
  border: none;
}
.gc-yellow-background {
  background-color: #f9dc62 !important;
}
.gc-dark-blue-color {
  color: #05164c !important;
}
.gc-navy-color {
  color: #5e6f87;
}
.gc-c1-blue {
  color: #3d52f6 !important;
}
.gc-c1-red {
  color: #eb556d !important;
}
.gc-c1-red-background {
  background-color: #eb556d !important;
}
.gc-c1-blue-background {
  background-color: #3d52f6 !important;
}
.gc-alert-text {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #d42a2a;
  letter-spacing: 0.2px;
}
.alert {
  border-radius: 8px !important;
}
.gc-primary-500 {
  color: #0397e7 !important;
}
.gc-primary-600 {
  color: #0069b4 !important;
}
.gc-gray {
  color: #686868 !important;
}
.gc-black {
  color: #000000 !important;
}
.gc-black-800 {
  color: #212121 !important;
}
.gc-gray-blue {
  background: #ecf1f8;
}
.gc-black-600 {
  color: #686868 !important;
}
.gc-black-500 {
  color: #959393 !important;
}
.gc-black-700 {
  color: #3f3b3b !important;
}
.gc-danger-600 {
  color: #d42a2a !important;
}
.gc-blue {
  color: #5d6f88;
}
.gc-text-small {
  font-size: 12px;
  line-height: 21px;
}
.gc-primary-btn {
  height: 52px !important;
  width: 177px !important;
}

.mt-8px {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.pt-10px {
  padding-top: 10px !important;
}
.mr-10px {
  margin-right: 10px !important;
}
.mt-40px {
  margin-top: 40px !important;
}
.mr-23px {
  margin-right: 23px !important;
}
.mt-12px {
  margin-top: 12px !important;
}
.mt-1px {
  margin-top: 1px !important;
}
.ml-8px {
  margin-left: 8px;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.icon-18 {
  width: 18px;
}
.w-20 {
  width: 20% !important;
}
.w-15 {
  width: 15% !important;
}
.font-size-48 {
  font-size: 48px !important;
  line-height: 58px;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.font-size-35 {
  font-size: 35px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.w-31 {
  width: 31%;
}
.mt-6px {
  margin-top: 6px;
}
.ml-40px {
  margin-left: 40px;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-line-hight-24 {
  line-height: 24px !important;
}
.info-icon {
  color: $icon-color;
}
.gc-text-drop-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.mobile-tr {
  display: none !important;
}
.mobile-item {
  display: none !important;
}
.gc-headline {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  text-align: left;
}
.gc-agent-card {
  margin-right: 76px;
  img {
    margin-bottom: 2px;
  }
  .selected {
    border: 4px solid #f82ba4 !important;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.32));
  }
}
.input-h45 {
  border-left: white !important;
  height: 45px;
}
input[type="radio"] {
  &:checked {
    background-color: #05164c !important;
    border: 1px solid #05164c !important;
    border-color: #05164c !important;
    background-color: #05164c;
    padding: 2px;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-clip: content-box;
  border: 1px solid #05164c;
  border-radius: 50%;
}
.custom-control-input {
  &:checked {
    ~ {
      .custom-control-label {
        &::before {
          background-color: #05164c !important;
          background-color: #05164c !important;
          border: 1px solid #05164c !important;
          border: 1px solid #05164c !important;
          border-color: #05164c !important;
          border-color: #05164c !important;
        }
      }
      .agent-settings-label {
        &::before {
          background-color: #05164c !important;
          border: 1px solid #05164c !important;
          border-color: #05164c !important;
        }
      }
    }
  }
}
button.btn-primary {
  border-color: unset;
  &:disabled {
    border-color: unset;
  }
}

div.gc-border-8px {
  border-radius: 8px;
}
input {
  font-family: Inter !important;
  box-shadow: none !important;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    &:hover {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
}
.gc-select__placeholder {
  font-family: Inter !important;
}
.gc-select-with-icon__placeholder {
  font-family: Inter !important;
}
.inter-font {
  font-family: Inter !important;
}
span {
  [contenteditable="true"] {
    font-family: Inter !important;
  }
  [contenteditable="plaintext-only"] {
    font-family: Inter !important;
  }
}

.gc-cta-button-primary {
  &:disabled {
    background: #959393 !important;
    opacity: unset !important;
  }
  background: #3d52f6;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  width: 131px;
  height: 48px;
  border: none !important;
}
.gc-icon-button {
  margin-bottom: 4px;
  margin-right: 8px;
}

.gc-dashboard-btn-element {
  width: 135px;
  height: 36px;
}
.gc-duotone-level1 {
  color: #08111a !important;
}

.gc-login-with-google {
  display: flex;
  background: #4285f4;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 10px;
  width: 100%;
  height: 56px;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  place-content: center;
  img {
    width: 24.33px;
    height: 24.33px;
    background: #ffffff;
    border-radius: 10px 0px 0px 10px;
  }
  .img-container {
    width: 65px;
    height: 56px;
    background: #ffffff;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.gc-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gc-yelp-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 20px;
  height: auto;
  width: 100%;
  margin: 32px 0px;
}
.yelp-select-business-type {
  border-radius: 10px !important;
  box-shadow: none !important;
  border-color: none !important;
}
.yelp-select-business-type__control {
  &:hover {
    box-shadow: none !important;
    border-color: #e0e0e0 !important;
  }
  box-shadow: none !important;
  border-color: #e0e0e0 !important;
  height: 40px !important;
  border-radius: 8px !important;
}
.yelp-select-business-type__indicator-separator {
  display: none !important;
}

.gc-form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0px 0px 12px 0px;
}
.gc-caption {
  font-size: 14px;
  line-height: 21px;
  bottom: 0px;
  font-family: "Inter";
  font-weight: 300;
}
.react-tel-input {
  input {
    &:disabled {
      cursor: not-allowed;
      background-color: #f2f2f2 !important;
      border: 1px solid #e0e0e0 !important;
      border-radius: 0.25rem !important;
    }
  }
  .form-control {
    align-items: center;
    width: 100% !important;
    height: 54px !important;
    background: #ffffff !important;
    border: 1px solid #e0e0e0 !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    font-weight: 500;
    &:focus-within {
      box-shadow: none !important;
      border: 1px solid !important;
      border-color: #0397e7 !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
    }
  }
  .gc-danger-input {
    border: 1px solid !important;
    border-color: #ff5151 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
  }
  .gc-success-input {
    border: 1px solid !important;
    border-color: #2ed477 !important;
    border-left: 1px solid #2ed477 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
  }
}
.gc-danger-input {
  border: 1px solid !important;
  border-color: #ff5151 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}
.gc-success-input {
  border: 1px solid !important;
  border-color: #2ed477 !important;
  border-left: 1px solid #2ed477 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}
.gc-border-1 {
  border-radius: 5px !important;
}
.form-control {
  &:focus {
    box-shadow: none !important;
  }
}
.gc-default-input {
  box-shadow: none !important;
  border: 1px solid !important;
  border-color: #0397e7 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}
.gc-verification-code {
  width: 288px;
  height: 52px;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  input {
    display: flex;
    width: 48px;
    height: 52px;
    left: 160px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    margin-right: 32px;
    font-weight: 300;
    font-size: 18px;
    &:last-child {
      margin-right: 0px;
    }
    &:focus-visible {
      box-shadow: none !important;
      border-color: none !important;
      outline: none !important;
    }
  }
}
.spinner-border {
  vertical-align: inherit !important;
}
.gc-input-h56 {
  input {
    border-left: white !important;
    height: 56px;
    border-left: none !important;
    border-left: white !important;
    height: 56px;
    border-left: none !important;
    font-size: 16px !important;
  }
  textarea {
    height: 56px;
  }
}
.gc-input-h52 {
  input {
    padding: 12px 16px;
    height: 52px;
  }
}
.gc-input-h80 {
  input {
    padding: 12px 16px !important;
    height: 80px;
    resize: none;
  }
}
:not(.conversight) > textarea {
  padding: 12px 16px !important;
  height: 80px;
  resize: none;
  &:focus {
    box-shadow: none !important;
  }
}
.gc-div-h80 {
  padding: 12px 16px !important;
  height: 80px;
  resize: none;
}
.gc-input-h108 {
  input {
    padding: 12px 16px !important;
    height: 108px;
    resize: none;
  }
  textarea {
    padding: 12px 16px !important;
    height: 108px;
    resize: none;
  }
  padding: 12px 16px !important;
  height: 108px;
  resize: none;
}
.gc-icon {
  border-right: none !important;
  border-radius: 0px 10px 10px 0px;
}
.gc-icon-right {
  border-left: none !important;
  border-radius: 10px 0px 0px 10px;
}
.gc-select {
  input {
    border: none;
    font-size: 16px;
    color: #495057;
  }
}
.gc-select-with-icon {
  input {
    border: none;
    font-size: 16px;
    color: #495057;
  }
}
.gc-select__menu {
  z-index: 2 !important;
}
.gc-select-with-icon__menu {
  z-index: 2 !important;
}
.gc-address-select {
  .gc-select__indicators {
    visibility: hidden !important;
  }
  .gc-select-with-icon__indicators {
    visibility: hidden !important;
  }
}
.gc-select-multi__value-container {
  height: 48px;
}
.gc-select-with-icon-multi__value-container {
  height: 48px;
}
.gc-select-multi__multi-value {
  background: #e9ecef;
  border: 1px solid #ced4da;
}
.gc-select-with-icon-multi__multi-value {
  background: #e9ecef;
  border: 1px solid #ced4da;
}
.gc-select-multi__multi-value__label {
  height: 24px;
  color: #212529 !important;
}
.gc-select-with-icon-multi__multi-value__label {
  height: 24px;
  color: #212529 !important;
}
.gc-select-multi__multi-value__remove {
  color: #212529;
  cursor: pointer;
}
.gc-select-with-icon-multi__multi-value__remove {
  color: #212529;
  cursor: pointer;
}
.gc-select-multi__clear-indicator {
  cursor: pointer;
}
.gc-select-with-icon-multi__clear-indicator {
  cursor: pointer;
}
.gc-select-multi__dropdown-indicator {
  cursor: pointer;
}
.gc-select-with-icon-multi__dropdown-indicator {
  cursor: pointer;
}
.gc-select-multi__option {
  cursor: pointer !important;
}
.gc-select-with-icon-multi__option {
  cursor: pointer !important;
}
.gc-select__option--is-selected {
  color: black !important;
  background-color: inherit !important;
  &::after {
    content: "\2713";
    color: gray;
    float: right;
  }
}
.gc-select-with-icon__option--is-selected {
  color: black !important;
  background-color: inherit !important;
  &::after {
    content: "\2713";
    color: gray;
    float: right;
  }
}
.gc-rounded-border-left {
  border-radius: 10px 0px 0px 10px !important;
}
.gc-rounded-border-right {
  border-radius: 0px 10px 10px 0px !important;
}
.gc-double-icon-border {
  border-radius: 0px !important;
  border-right: none !important;
  border-left: none !important;
}
.gc-divider {
  &:nth-child(1n + 2) {
    margin-top: 24px;
  }
}
.gc-divider.d-flex {
  gap: 24px;
}
.gc-overlay-icon {
  position: absolute;
  z-index: 1;
  left: auto;
  margin-top: 16px;
  margin-left: 12px;
}
.mt-32px {
  margin-top: 32px !important;
}
.mt-13px {
  margin-top: 13px !important;
}
.gc-select-with-icon__value-container {
  margin-left: 50px !important;
}
.gc-select__single-value {
  color: #495057 !important;
}
.gc-select-with-icon__single-value {
  color: #495057 !important;
}
.copy-config__value-container {
  padding: 8px 16px !important;
}
.copy-config__single-value {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}
.copy-config__placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}
.copy-config__indicator-separator {
  display: none;
}
.copy-config__control {
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
}
.gc-select__control {
  height: 54px;
  border-radius: 10px !important;
  box-shadow: none !important;
  &:hover {
    box-shadow: 0 !important;
  }
}
.gc-select-with-icon__control {
  height: 54px;
  border-radius: 10px !important;
  box-shadow: none !important;
  &:hover {
    box-shadow: 0 !important;
  }
}
.gc-danger-select {
  .gc-select__control {
    border-color: #ff5151;
  }
  .gc-select-with-icon__control {
    border-color: #ff5151;
  }
}
.gc-success-select {
  .gc-select__control {
    border-color: #2ed477;
  }
  .gc-select-with-icon__control {
    border-color: #2ed477;
  }
}
.gc-default-select {
  .gc-select__control {
    border-color: #0397e7;
  }
  .gc-select-with-icon__control {
    border-color: #0397e7;
  }
}
.gc-radio-container {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
  }
}

.gc-nav {
  position: absolute;
  right: 0px;
  color: #959393;
  font-size: 14px;
  line-height: 28px;
  padding: 16px 24px 16px 16px;
  font-weight: 300;
  z-index: 2;
}
.pagination {
  justify-content: flex-end;
  align-items: center;
}
.page-link {
  margin-left: auto !important;
  background-color: #fff0 !important;
  border: none !important;
  color: #000000 !important;
}
.page-item {
  .selected {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
  }
}
.gc-border-6px {
  border-radius: 6px !important;
}
a[disabled] {
  pointer-events: none;
  color: #999 !important;
}
.hand-pointer {
  cursor: pointer;
}
.dropdown {
  .btn {
    &:disabled {
      background-color: #f2f2f2 !important;
    }
  }
}
.tooltip {
  z-index: 2147483007 !important;
}
#button-tooltip {
  table {
    text-align: left !important;
  }
}
#custom-input {
  .input-group-text {
    background-color: white !important;
  }
  input {
    &:focus {
      box-shadow: none !important;
      border-color: none !important;
    }
  }
}
.input {
  background-color: white !important;
}
.businessTypeDropDown {
  .select {
    width: 20rem;
  }
}
.businessConfirmation {
  display: flex;
  flex-direction: row;
  span {
    margin-right: 5px;
  }
}
.gc-text-button {
  font-family: Inter;
  font-size: 14px !important;
  line-height: 32px !important;
  font-weight: 300 !important;
}

.select {
  input {
    height: 31px;
  }
}

.name-section {
  display: flex;
  justify-content: space-between;
  div {
    width: 100%;
  }
  .gc-rounded-border {
    border-left: 1px solid #ced4da !important;
    border-radius: 8px;
    padding: 12px 16px;
    &:focus {
      border: 1px solid #80bdff !important;
    }
  }
  .gc-success-input {
    border-left: 1px solid #2ed477 !important;
  }
  .gc-danger-input {
    border-left: 1px solid #ff5151 !important;
  }
}
.gc-agent-image {
  width: 96px;
  border: 4px solid #ecf1f8;
}
.agent-row {
  .gc-agent-card {
    &:nth-child(3n + 3) {
      margin-right: 0px;
    }
  }
}

.password {
  input {
    border-right: white !important;
    height: 45px;
  }
  .input-group-text {
    background-color: white !important;
    border: none;
    border-radius: 0px 2px 2px 0px !important;
    border: 1px solid #ced4da !important;
    border-left: none !important;
  }
}

div.manage-user-mobile {
  display: none !important;
}
.billing-details-form {
  button {
    &:first-of-type :not(:last-child) {
      display: none !important;
    }
  }
}
button.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-deleted-feedback {
  .text-container {
    max-width: 560px !important;
    width: 100%;
    padding: unset;
    height: 100%;
  }
  textarea {
    height: 168px !important;
  }
  .footer {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    border-radius: 0px 0px 10px 10px;
  }
}
.agent-not-found {
  .text-container {
    max-width: 560px !important;
    width: 100%;
    padding: unset;
    height: 100%;
  }
}
.knowledge-obe-container {
  background: #05164c;
  border-radius: 16px;
  padding-right: 24px;
  img {
    width: 120px;
  }
  .need-help {
    margin-left: auto;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  a {
    color: #63d8d4;
  }
}
.disabled-overlay {
  position: absolute;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
  cursor: not-allowed;
  z-index: 2;
}
.onboarding-wix-logo {
  width: 80px;
  position: absolute;
  bottom: -8px;
  margin-left: 205px;
}
.wix-knowledge-obe {
  .logos {
    img {
      width: 80px;
      &:last-of-type {
        width: 104px;
      }
    }
  }
}

.gc-greeting-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 12px;
  .loading {
    width: 25px;
    margin-right: 8px;
  }
}

.gc-grey-form {
  padding: 24px;
  width: 100%;
  height: auto;
  background: #ecf1f8;
  box-shadow: 0px 0px 1px rgb(40 41 61 / 4%), 0px 2px 4px rgb(96 97 112 / 16%);
  border-radius: 20px;
  margin: 24px 0px 24px 0px;
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
}
