// Base colors
$base-blue: #3d52f6;
$base-dark-blue: #05164c;
$base-red: #eb556d;
$base-cyan: #63d8d4;
$base-yellow: #f9dc62;

// Navigation colors
$navigation-active: #ecf1ff;
$navigation-inactive: #98a7bb;
$navigation-disabled: #e0e0e0;

// Interactivity
$standard-hover: #fafafb;
$super-cta-hover: #c3485c;
$primary-hover: #3142c3;
$secondary-hover: #040e2f;
$active-element: #86b7fe;

//Element
$active-element: #86b7fe;
$body-color: #212529;
$icon-color: rgba(0, 0, 0, 0.54);
$label-color: #404040;

// Border and Divider
$border: #eaeaea;
$divider: #eaeaea;
$action: #ecf1f8;

// Black to White Gradient
$true-black: #000000;
$base-black: #212121;
$light-black: #686868;
$base-gray: #959393;
$light-gray: #f2f2f2;
$white: #ffffff;
$standard-background: #f8f8f8;

// System Status
$success: #2ed477;
$warning: #ff8310;
$error: #ff5151;
$info: #07c8f3;

// Background gradients
$johny-gold: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #d2b38f 100%);
$graham-gray-blue: radial-gradient(50% 50% at 50% 50%, #f5f6fa 0%, #99a2c3 100%);
$alex-pink: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #deb3cb 100%);
$bella-gray: radial-gradient(50% 50% at 50% 50%, #fafafa 0%, #bab2b2 100%);
$rosie-teal: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #83c6bc 100%);
$hero-gray: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #93a4b9 100%);

// Background transparent
$transparent-color: rgba(0, 0, 0, 0);

.gc-error-color {
  color: $error;
}

.gc-color-inherit {
  color: inherit;
}
