@media screen and (max-width: 1200) {
  .mobile-nav {
    .plan-bottom {
      position: relative !important;
    }
  }
  #sidebar {
    overflow-y: auto !important;
  }
}

@media (min-width: 1024px) {
  .gc-container {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 1024px) {
  div.hide-on-mobile {
    display: none !important;
  }

  #onboarding {
    .gc-callout {
      .speak-arrow {
        display: none;
      }
      position: relative;
      bottom: auto;
      margin: auto;
      background: none;
      width: auto;
      padding: auto;
      filter: none;
      text-align: center;
    }
    .main-container {
      padding: 0 48px 48px;
      background-size: 0 !important;
      background-position-x: -713px !important;
      height: auto !important;
      min-height: auto !important;
      margin-bottom: 48px;
      .gc-headline {
        padding-top: 0px !important;
        text-align: center;
      }
      > div {
        &:not(.gc-progress-bar) {
          flex-direction: column;
        }
      }
    }
  }
  .gc-callout-notext {
    position: relative;
    bottom: auto;
    margin: auto;
    background: none;
    width: auto;
    padding: auto;
    filter: none;
    text-align: center;
  }
  .gc-progress-bar {
    width: calc(100% + 96px);
  }
  .gc-avatar-container {
    align-items: center;
  }
  .gc-avatar {
    display: block !important;
  }
  .onboarding-wix-logo {
    top: 30px;
    left: 170px;
  }
}
@media screen and (max-height: 830px) {
  .sidebar-logout-employee {
    position: relative !important;
  }
  #sidebar {
    height: 100vh;
    overflow: auto;
  }
}
@media only screen and (max-width: 560px) {
  .subscription-action {
    width: 100%;
    justify-content: center;
  }
  div.knowledge-table {
    padding: unset;
    border-radius: unset;
    // Hide table header on mobile
    div.header {
      display: none;
    }
    .table-row {
      padding: 0;
      margin: 0;
    }
  }
  .gc-progress-bar {
    background: #ecf1f800 !important;
    position: absolute;
    top: 16px;
    border-radius: 16px;
    left: 16px;
    width: calc(100% - 90px) !important;
  }
  .gc-divider.d-flex {
    flex-direction: column;
  }
  .copy-config {
    button {
      font-size: 14px !important;
      padding: 4px 8px !important;
    }
  }
  .calls-table {
    thead {
      display: none;
    }
    tbody {
      tr {
        td {
          &:last-of-type {
            padding-top: 0px !important;
          }
        }
      }
    }
    .divider-tr {
      td {
        &:first-of-type {
          display: none !important;
        }
        &:last-of-type {
          display: none !important;
        }
      }
    }
    div.card-tag {
      margin-top: 0px !important;
    }
  }
  .gc-avatar {
    display: none !important;
    margin-top: -124px;
  }
  .gc-nav {
    a {
      color: #fff !important;
    }
    * {
      &:not(.gc-dark-blue-color) {
        display: none;
      }
    }
    .gc-dark-blue-color {
      z-index: 1;
    }
  }
  .form-content {
    .create-account-hint {
      color: #fff !important;
      a {
        color: #63d8d4 !important;
      }
    }
    margin-top: -102px;
    z-index: 1;
  }
  .cyan-on-mobile {
    color: #63d8d4 !important;
  }
  div.progress-bar-filled {
    background-color: #05164c00;
    border-radius: 16px !important;
    color: #ffffff !important;
    position: absolute;
    left: 8px;
    text-align: left !important;
    min-width: 100px;
    font-weight: 300 !important;
  }
  .gc-headline {
    color: #ffffff;
    font-weight: 500;
  }
  .gc-select-business-source {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 20px;
    height: auto;
    width: 100%;
    margin: 5px 0px;
  }
  .show-on-small-screen {
    display: block;
  }
  .call-out-agent-image {
    width: 250px;
    margin-left: -118px;
    margin-top: -21px;
  }
  .gc-callout {
    color: #ffffff;
  }
  .agent-list {
    background: #ecf1f8;
    border-radius: 20px;
  }
  .main-layout {
    // background-color: #05164c !important;
    // background-image: none !important;
    .col {
      margin: 0px;
      width: 100%;
      padding: 0px;
    }
    .row {
      margin: 0px;
      width: 100%;
      padding: 0px;
    }
    .container {
      margin: 0px;
      width: 100%;
      padding: 0px;
    }
  }
  .mobile-item {
    display: flex !important;
  }
  .main-container {
    box-shadow: none;
    background: none !important;
    padding: 0px;
    padding-right: 16px !important;
    padding-left: 16px !important;
    margin-bottom: 16px !important;
    margin-top: 128px !important;
  }
  .gc-form-label {
    font-size: 18px !important;
  }
  .gc-greeting-picker {
    .mobile {
      display: flex !important;
      justify-content: space-around !important;
    }
    .gc-agent-card {
      text-align: auto !important;
      margin-right: 0px;
      display: flex;
      flex-direction: column;
    }
  }
  .edit-agent-modal {
    .gc-agent-card {
      margin-right: 0px !important;
    }
  }
  #onboarding {
    div.gc-yelp-card {
      flex-direction: column;
      margin: 16px 0px;
      .gc-yelp-image {
        width: 100%;
        height: 210px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
    div.gc-yelp-content {
      width: 100% !important;
      height: auto !important;
    }
    div.gc-yelp-secondary-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
      border-radius: 20px;
      height: auto;
      width: 100%;
      margin: 16px 0px;
    }
  }
  .gc-yelp-secondary-content {
    position: static;
    padding: 16px 16px 16px 24px;
    width: 100%;
    height: auto;
  }
  .calls-obe-main {
    margin-top: auto !important;
  }
  .knowledge-obe-container {
    flex-flow: wrap-reverse !important;
    padding: 24px;

    .need-help {
      position: absolute;
      right: 45px;
      .white-button {
        margin-left: 30px;
      }
    }
  }
  .gc-b2 {
    text-align: left;
  }
  .gc-h2 {
    text-align: left;
  }

  .my-bd-obe {
    .text-container {
      height: auto;
    }
    .calls-obe-bd-top {
      height: auto !important;
    }
    .bd-container {
      height: auto !important;
    }
  }

  div.desktop-row,
  tr.desktop-row {
    display: none !important;
  }

  tr.divider-tr {
    display: none;
  }
  div.mobile-knowledge-item {
    padding: 16px;
    display: flex !important;
    flex-direction: row;
    .knowledge-action-card {
      white-space: nowrap;
    }
    .example {
      display: none;
    }
  }
  table {
    tr.mobile-tr {
      display: table-row !important;
      border-bottom: 1px solid #eaeaea;
      td {
        visibility: visible !important;
      }
    }
  }
  .open-hours-period {
    .open-hours-checkbox {
      margin-left: 8px !important;
    }
    .to-label {
      margin-left: 8px !important;
      margin-right: 8px !important;
    }
  }
  .open-hours-row {
    flex-wrap: wrap;
  }
  .mobile-calls-item {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 16px;
  }
  #agent-settings {
    padding: 16px;
    padding-bottom: 0px !important;
  }
  .agent-settings {
    padding: 16px !important;
  }
  .settings-border {
    padding: 16px !important;
    margin: 16px 0px !important;
  }
  .forward-messages-container {
    #agent-settings {
      padding: 0px;
    }
  }
  #message-actions {
    .agent-profile {
      margin: 16px !important;
      margin-top: 0px !important;
    }
  }
  .agent-profile {
    .agent-card {
      margin-left: 0px !important;
      padding: 0px;
    }
    &:first-of-type {
      margin-top: 0px !important;
    }
    .agent-info-container {
      margin-left: 16px;
      width: 100%;
    }
  }

  .settings-card-container {
    .gray-card {
      width: 100% !important;
      max-width: 216px;
      height: auto !important;
      .card-title {
        margin-bottom: 0px !important;
      }
    }
  }
  div.manage-user-desktop {
    display: none !important;
  }
  div.manage-user-mobile {
    display: flex !important;
  }
  .edit-agent-profile {
    flex-direction: column;
    align-items: center;
  }
  .agent-info-container {
    margin-left: 0px !important;
  }
  div.agent-info-container {
    div.gray-card {
      margin-left: 0px !important;
      &:first-of-type {
        margin-top: 16px;
      }
    }
  }
  div.modal-header {
    padding: 16px 16px 0px 16px !important;
  }
  div.modal-body {
    padding: 0px 16px 16px 16px !important;
  }
  div.modal-footer {
    padding: 16px !important;
  }
  .upgrade-plan-left {
    min-width: auto !important;
  }
  .account-setting-item {
    margin-top: 0px !important;
    #agent-settings {
      padding-top: 0px !important;
    }
    .account-setting-card {
      margin: 16px 0px;
    }
    #message-actions {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  div.account-settings-header-buttons {
    margin-left: 16px !important;
  }
  button.account-setting-button {
    margin: 0px 16px 0px 0px !important;
  }
  .delete-account {
    align-items: flex-start !important;
    padding-left: 16px;
  }
  .calls-report {
    margin-top: 0px !important;
  }
  .name-section {
    .gc-input-h56 {
      width: auto !important;
    }
  }
  .account-setting-card {
    .section {
      .info-container {
        max-width: 80% !important;
      }
      .input-group {
        width: 100% !important;
      }
      .react-tel-input {
        width: 100% !important;
      }
    }
  }

  select.open-hours-dropdown {
    background: white url(https://i.ibb.co/2g2DLV7/png.png) no-repeat 80px center !important;
    width: 100px !important;
    padding: 4px 24px 4px 4px !important;
  }
  div.save-changes-button {
    [role="button"] {
      padding: 8px 8px !important;
    }
  }
  .plan-card {
    .plan-details {
      .plan-details-row {
        align-items: center !important;
      }
      .card-details {
        border-right: none !important;
        border-bottom: 1px solid #d2deef;
      }
      .font-size-48 {
        font-weight: 500 !important;
        font-size: 30px !important;
        line-height: 39px;
      }
    }
    .description-row {
      align-items: center !important;
    }
  }
  div.plan-card {
    .description-row {
      &:first-of-type {
        margin-top: 16px !important;
      }
    }
    .plan-details-row {
      &:not(:first-of-type) {
        margin-top: 8px !important;
        justify-content: center !important;
      }
    }
  }
  div.plan-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .plan-container {
    .enabled-knowledge {
      display: none;
    }
    .plan-usage {
      align-items: flex-start !important;
      margin-top: 16px;
    }
    .location-table-header {
      &:not(:first-of-type) {
        display: none !important;
      }
    }
  }
  .billing-details-form {
    button {
      &:first-of-type {
        display: flex !important;
      }
    }
  }
  .nav-item {
    width: calc(100% / 3);
  }

  .onboarding-wix-logo {
    display: none;
  }
}
